import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, IconButton, LinearProgress, Typography } from '@mui/material';
import AWS from 'aws-sdk';
import siva from '../../../assets/imageUpload.svg';
import { makeStyles } from '@mui/styles';
import RemoveComponent from './RemoveComponent';
import BackComponent from './BackComponent';
import { datafinder } from '../../PostForm/component';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from '../../../slices/filterSlice';
import { MdArrowBackIosNew } from "react-icons/md";
import { ImageTagsR, ImageTagsC } from '../data'
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';




interface UploadedImage {
  id: number;
  name: string;
  file: File;
  url: string;
  isCover: boolean;
  isVisible: boolean;
  selectedTag: string | null;
  ImageTag: string | null;
  progress: number;
  isUploaded: boolean;
  uri: any
}

interface UploadPhotoProps {
  // onNext: () => void;
  // onBack: () => void;
  // onReset: () => void;
  setFullData: any
  passedData?: any
  closeModal: any
}

const useStyles = makeStyles({
  imagePreviewContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // Adjust the number of columns
    gap: '20px',
    marginTop: '20px',
  },
  imagePreview: {
    position: 'relative',
    border: '2px solid #cccccc',
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '200px', // Fixed height for each image preview
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure image covers the container
    cursor: 'pointer',
  },
  setCoverButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  removeButton: {
    position: 'absolute',
    top: '10px', // Adjusted for better positioning
    right: '10px', // Adjusted for better positioning
  },
  tagSelect: {
    width: '100%',
    marginTop: '10px',
    padding: '5px',
    borderRadius: '5px',
  },
  progressBar: {
    marginTop: '10px',
  },
  progressText: {
    textAlign: 'right',
    marginTop: '5px',
  },
});

const UploadPhoto: React.FC<UploadPhotoProps> = ({ setFullData, closeModal

}) => {
  const classes = useStyles();
  const passedData = useSelector((state: any) => state.usersInfo.users);
  console.log("redux check image", passedData)
  const { propImages, addPropImage, userData } = useStepperContext();
  const [selectedFiles, setSelectedFiles] = useState<UploadedImage[]>(passedData[0].images);
  const res_com = passedData.find((item: any) => ['Property Type']).res_or_com
  const propertyTypeItem = passedData.find((item: any) => item.property_type);
  const proptype = propertyTypeItem ? propertyTypeItem.property_type : undefined;
  const [mypass, setMypass] = useState(passedData)
  const imageTags = ['hall', 'kitchen', 'room', 'balcony'];
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);


  console.log("proptype", proptype, res_com)

  const s3 = new AWS.S3({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRETACCESS,
  });

  const generateUniqueNumber = () => Math.floor(Math.random() * 100000);

  const handleUploadChange = async (files: File[]) => {
    if (files.length === 0) return;

    const maxFiles = 6;
    const newFiles: File[] = files.slice(0, maxFiles - selectedFiles.length);

    if (selectedFiles.length + newFiles.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }
    setIsUploading(true); // Start loading

    const newSelectedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const s3Url = await uploadImageToS3(file);

        return {
          id: generateUniqueNumber(),
          name: file.name,
          file,
          uri: s3Url,
          isCover: false,
          isVisible: true,
          selectedTag: null,
          progress: 100,
          isUploaded: true,
        };
      })
    );

    setIsUploading(false); // Stop loading


    setSelectedFiles((prevFiles: any) => {
      const updatedFiles = [...prevFiles, ...newSelectedFiles];
      addPropImage(updatedFiles);
      return updatedFiles;
    });
  };

  const uploadImageToS3 = async (file: File): Promise<string | null> => {
    const fileName = `image_${Date.now()}.jpg`;
    const params = {
      Bucket: 'vattara-img',
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadPromise = s3
        .upload(params)
        .on('httpUploadProgress', (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setSelectedFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file === file ? { ...f, progress: percent } : f
            )
          );
        })
        .promise();

      const uploadResponse = await uploadPromise;
      return uploadResponse.Location;
    } catch (error) {
      console.error(`Error uploading ${file.name} to S3: ${error}`);
      return null;
    }
  };

  const handleSetCover = (id: number) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      isCover: file.id === id,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleTagSelect = (id: number, tag: string) => {
    const updatedFiles = selectedFiles.map((file) => ({
      ...file,
      selectedTag: file.id === id ? tag : file.selectedTag,
    }));
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };

  const handleRemove = (id: number) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
    addPropImage(updatedFiles);
  };
 


  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleUploadChange(acceptedFiles),
    accept: {
      'image/png': [], // Accept PNG images
      'image/jpeg': [], // Accept JPEG images
      'image/jpg': []   // Accept JPG images
    } as Accept,
    maxFiles: 6,
  });
  const handleReset = () => {
    setSelectedFiles([]);
    addPropImage([]);
    // onReset();
  };
  function handleSubmit() {
 // Check if any images are selected
 if (selectedFiles.length > 0) {
  // Check if there is at least one cover image selected
  const hasCoverImage = selectedFiles.some(file => file.isCover);

  if (!hasCoverImage) {
    alert("Please select at least one image as the cover before proceeding.");
    return; // Prevent proceeding if no cover image is selected
  }
}

// If the checks are passed, proceed with submission logic
console.log("image check", selectedFiles);

    console.log("image check", selectedFiles)
    const update = mypass.map((item: any) => {
      return {
        ...item,
        images: selectedFiles
      }
    })
    console.log("for paassed", update)
    setMypass(update)
    dispatch(setUsers(update));
    setFullData(update)
    closeModal()
  }
  return (
    <div className="custom-scrollbar p-2 overflow-hidden">
    <div className='flex items-center gap-2 justify-center'>
      {/* <IconButton> <MdArrowBackIosNew onClick={ closeModal}  className=' w-8 h-8  z-40  md:flex' /></IconButton> */}
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
        Upload Photos
        </Box>     <IconButton
onClick={ closeModal}        
      sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:"flex",
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>   </div> 
     
            <div
        {...getRootProps()}
        className='mx-[7%]'
        style={{
          border: '2px dashed  #cccccc',
          borderRadius:"10px",
          backgroundColor:"#E0E1EE",
          padding:"5px",
          textAlign: 'center',
          marginTop: '10px',
        }}
      >
        <input {...getInputProps()} />
        <img
          src={siva}
          alt="description"
          className="w-[100px] mb-[10px] custom-xxl:ml-[40%] sm:ml-[40%] msm:ml-[30%] rounded 2xl"
        />
        <div style={{ marginRight: '10%', fontSize:"15px",fontWeight:700 }}>
          Drop your image here, or <span>browse</span>{' '}
        </div>
        <h4 style={{ marginRight: '10%',fontSize:"10px" }}>Supports: PNG, JPG, JPEG</h4>
        <h4 style={{ marginRight: '10%',fontSize:"10px",color:"red",marginTop:"5px" }}>Upload Maximum Upto 6 Images</h4>

      </div>
      {isUploading && (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <CircularProgress />
  </div>
)}


<div className="grid msm:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-3 mx-[5%]">
        {selectedFiles.map((file) => (
          <div
            key={file.id}
            className={`relative w-full h-[142px] border-1 border-gray-300 rounded-2xl  shadow-md transition-transform duration-300 ease-in-out ${
              file.isCover ? 'transform scale-105 shadow-lg' : ''
            }`}
          >
            <div className="relative w-full h-full overflow-hidden rounded-2xl">
              <img
                src={file.uri}
                alt={file.name}
                className="w-full h-full cursor-pointer"
                onClick={() => {}}
              />
             <button
  className={`absolute top-0 text-white text-xs px-2 py-1 w-full rounded-t-2xl cursor-pointer ${
    file.isCover ? 'bg-[#0CB7D1]' : 'bg-black bg-opacity-50'
  }`}
  onClick={() => handleSetCover(file.id)}
>
  {file.isCover ? 'Cover Image' : 'Set as Cover'}
</button>
<button
  className="absolute top-0 right-0  text-white text-xs p-1 rounded cursor-pointer"
  onClick={() => handleRemove(file.id)}
>
  <CancelIcon fontSize="small" />
</button>
              {proptype !== 'Plot / Land' && (
                <div className="absolute bottom-2 w-full px-2">
                  <select
                     className="w-full px-2 relative py-1 rounded border border-gray-300 cursor-pointer bg-white mt-2"
                    onChange={(e) => handleTagSelect(file.id, e.target.value)}
                    value={file.selectedTag || ''}
                  >
                    <option value="">Select Tag</option>

                    {/* Residential Tags */}
                    {res_com === "Residential" && (
                      <>
                        {/* Exterior */}
                        {Array.isArray(ImageTagsR.Exterior) && (
                          <optgroup label="Exterior">
                            {ImageTagsR.Exterior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Interior */}
                        {Array.isArray(ImageTagsR.Interior) && (
                          <optgroup label="Interior">
                            {ImageTagsR.Interior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Amenities */}
                        {Array.isArray(ImageTagsR.Amenities) && (
                          <optgroup label="Amenities">
                            {ImageTagsR.Amenities.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Miscellaneous */}
                        {Array.isArray(ImageTagsR.Miscellaneous) && (
                          <optgroup label="Miscellaneous">
                            {ImageTagsR.Miscellaneous.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </>
                    )}

                    {/* Commercial Tags */}
                    {res_com === "Commercial" && (
                      <>
                        {/* Exterior */}
                        {Array.isArray(ImageTagsC.Exterior) && (
                          <optgroup label="Exterior">
                            {ImageTagsC.Exterior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Interior */}
                        {Array.isArray(ImageTagsC.Interior) && (
                          <optgroup label="Interior">
                            {ImageTagsC.Interior.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Amenities */}
                        {Array.isArray(ImageTagsC.Amenities) && (
                          <optgroup label="Amenities">
                            {ImageTagsC.Amenities.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}

                        {/* Miscellaneous */}
                        {Array.isArray(ImageTagsC.Miscellaneous) && (
                          <optgroup label="Miscellaneous">
                            {ImageTagsC.Miscellaneous.map((tag: any) => (
                              <option key={tag.id} value={tag.label}>
                                {tag.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </>
                    )}
                  </select>
                </div>
              )}

            </div>
            {/* {file.isUploaded && (
              <div className="mt-2  flex flex-col">
                <LinearProgress variant="determinate" value={file.progress} sx={{width:"50%"}} />
                <Typography className="text-[5px]">
                  {file.progress}%
                </Typography>
              </div>
            )} */}
          </div>
        ))}
      </div>

      <Box className=" w-full flex justify-center items-center mt-2">
            <button
              type="submit"
              onClick={handleSubmit} 
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]   custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Continue
            </button></Box>
    </div>
  );
};

export default UploadPhoto;
