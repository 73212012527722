import { Button } from '@mui/material';
import React, { useState } from 'react';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { setUserLoginData } from '../../slices/userDataSlice'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';
import CloseIcon from '@mui/icons-material/Close';
import { Box,  IconButton, } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



interface ListProps {
    onClose: () => void;
    phone: any;
    UserId?: any
}
// AWS S3 Configuration
AWS.config.update({
    region: 'us-east-1',
    accessKeyId:"AKIAXKPUZW5AH27B7EGM",
    secretAccessKey:"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", 
});
const S3 = new AWS.S3();
const data = [
    { label: "Owner" },
    { label: "Agent" },
    { label: "Builder" },
    { label: "Promoter" }
];

interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}
interface BodyType {
    user_id: string;
    name: string;
    email: string;
    lang: string;
    company_logo?: string;
    category: string | undefined;
    company_name?: string;

}
interface UserLoginData {
    user_id?: any;
    name?: string;
    phone?: any;
    email?: string;
    company_logo?: string;
    company_name?: string;
    lang?: string;
    category?: string | undefined;
    token?: string; // Ensure token is a string
    encryptedToken?: string; // Add encryptedToken if necessary
}
const handleClose = () => {


};

const List: React.FC = ({ }) => {
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>(loginData.name);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState<string>(loginData.email);
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    
    console.log(loginData)

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
        console.log(value);

    }

    // Function to handle profile image upload
    const handleProfileImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Resize the image
            Resizer.imageFileResizer(
                file,
                800, // maxWidth
                800, // maxHeight
                'JPEG', // compressFormat
                90, // quality
                0, // rotation
                (uri) => {
                    setLoading(true); // Start loading

                    uploadFileToS3('vattara-img', uri as File).then((fileUrl) => {
                        setProfileImage(fileUrl);
                        setLoading(false); // Stop loading

                    }).catch((error) => {
                        console.error('Error uploading profile image:', error);
                        setLoading(false); // Stop loading on error

                    });
                },
                'blob' // outputType
            );
        }
    };

    const uploadFileToS3 = async (bucketName: string, file: Blob) => {
        const fileName = `profile_${Date.now()}`;
        const params = {
            Bucket: bucketName,
            Key: fileName,
            Body: file,
            ContentType: file.type,
        };
        try {
            const uploadResponse = await S3.upload(params).promise();
            return uploadResponse.Location;
        } catch (error) {
            throw new Error(`Error uploading ${fileName} to S3: ${error}`);
        }
    };

    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleCompanyName(event: React.ChangeEvent<HTMLInputElement>) {
        setCompanyName(event.target.value);
    }


    async function handleSubmitForm() {


        let errors: FormErrors = {};

        if (!name && !loginData.name) {
            errors.name = 'Please enter your name';
        }

        if (!email && !loginData.email) {
            errors.email = 'Please enter your email';
        } else if (email) {
            // Validate email format using a regular expression
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(email)) {
                errors.email = 'Please enter a valid email address';
            }
        }

        // Check if the category requires a company name
        if (category !== "Owner" && !companyName) {
            errors.companyName = 'Please enter your company name';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id: loginData.user_id,
            name: name,
            email: email,
            phone: loginData.phone,
            lang: "en",
            company_logo: profileImage || '',
            company_name: category !== "Owner" ? companyName : undefined,
            category: category,
            token: loginData.token,
            encryptedToken:loginData.encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userDetail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body)) 
            navigate('/PostForm')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
       
          <Box
          sx={{
            display: 'flex',
            // display:"flex",
            alignItems: 'center',
            justifyContent:"center",
            position: 'absolute',
            height:"100%",
            width:"100%",
          
            zIndex: 10,
          }}
          >
          <Box
            sx={{
              bgcolor: 'white',
            //   border: '0.5px solid',
              borderColor: 'gray.300',
              borderRadius: '20px',
              mx: { xs: 4, md: 10 }, // Responsive margins
              overflowY: "hidden",
              width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              position: 'relative',
              boxShadow: 3, // Add box shadow here
          
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',height:{xs:"500px",sm:"500px",md:"400px",lg:"400px",xl:"500px"}, }}> 
                                     <Box sx={{width:"10%",display:{xs:"none",sm:"none",md:"none",lg:"flex"}}}>
                
                          <div className=" z-10">
                                <div className="absolute md:-top-[10%] md:-left-[10%] lg:-left-[10%]  sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#0CB7D1" }}></div>
          
                            </div>
                </Box>
                <Box sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"45%"},height:"100%",justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column"}}>
                <IconButton
     onClick={() => navigate(-1)}
     sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    display:"flex",
                    color: 'gray.500',
                  }}
                >
                  <CloseIcon />
                </IconButton>   
                <div className='z-50'>

<div className='mt-[2%] z-50  sm:flex sm:flex-col justify-center items-center md:block'>
    <div className='mb-3' style={{fontSize:"14px",fontWeight:"bold"}}>Enter your contact information</div>
    <div className='mb-3'>
        <div className='flex gap-3 mb-1'>
            <div style={{fontSize:"14px"}}>Name</div>
            <input value={name }  onChange={handleName} className='border-none outline-none' style={{fontSize:"14px"}} />
        </div>
        {showAlert && formErrors.name && (
            <div className='absolute ml-4 z-10 font-normal text-xs text-[#f44336]' style={{fontSize:"10px"}}>
                {formErrors.name}
            </div>
        )}
        <div className='border border-1 border-[#1F1F1F] w-64'></div>
    </div>
    <div className='mb-3'>
        <div className='flex gap-3 mb-1'>
            <div style={{fontSize:"14px"}}>E-Mail</div>
            <input value={email}     type="email" // Set the type to "email"
 onChange={handleEmail} className='border-none lowercase outline-none' style={{fontSize:"14px"}}/>
        </div>
        {showAlert && formErrors.email && (
            <div className='absolute ml-4 z-10 font-semibold text-xs text-[#f44336]' style={{fontSize:"10px"}}>
                {formErrors.email}
            </div>
        )}
        <div className='border border-1 border-[#1F1F1F] w-64'></div>
    </div>
    <div>
        <div className='flex gap-3 mb-1'>
            <div style={{fontSize:"14px"}}>Phone</div>
            <input value={loginData.phone} className='border-none outline-none' readOnly  style={{fontSize:"14px"}}/>
        </div>
        <div className='border border-1 border-[#1F1F1F] w-64'></div>
    </div>
</div>
<div className={`${details ? "" : ""} sm:flex sm:flex-col justify-center items-center md:block`}>
    <div className='flex items-end mt-2' style={{fontSize:"14px",fontWeight:"bold",}}>I’m a {category}</div>
    <div className='flex gap-1 ml-2 mt-2'> 
    {
        data.map((item) => (
            <div key={item.label} className="flex items-center">
                <div 
                    onClick={() => (handleChange(item.label))} 
                    className={`p-1 px-3 rounded-2xl ${category === item.label ? 'bg-yellow-400' : 'bg-[#FCEAB6]'}`} 
                    style={{ fontSize: "11px" }}
                >
                    {item.label}
                </div>
                {category === item.label && (
                    <CheckCircleOutlineIcon style={{ color: 'green',position:"absolute", fontSize:"13px",marginLeft:"3px"}} />
                )}
            </div>
        ))
    }
</div>
</div>
<div className={`${details ? "flex flex-col ml-1 md:block" : "hidden"} `}>
    <div className='flex justify-start mt-2' style={{fontSize:"14px"}}>Company Details</div>
    <div className='flex flex-col'>
        <input className='outline-none placeholder:font-light placeholder:pl-4 mb-1' style={{fontSize:"14px"}} onChange={handleCompanyName} placeholder='Company name ' />
        {showAlert && formErrors.companyName && (
            <div className='ml-4 z-10 font-semibold text-xs text-[#f44336]' style={{fontSize:"10px"}}>
                {formErrors.companyName}
            </div>
        )}
        <div className='border border-1 flex justify-center items-center border-[#1F1F1F] w-64'></div>
    </div>
     <div className='flex items-center mt-2'>
        <label htmlFor="profile-image-upload" className="cursor-pointer">
            <img src={profileImage ||profile
            } alt="Profile" className="rounded-full bg-slate-500 w-10 h-10 cursor-pointer" />
            <input
                type="file"
                accept="image/*"
                onChange={handleProfileImageUpload}
                className="hidden"
                id="profile-image-upload"
            />
        </label>
        <div className="flex flex-col ml-2">
        <div style={{fontSize:"14px"}}
        onClick={() => {
            const inputElement = document.getElementById('profile-image-upload') as HTMLInputElement | null;
            if (inputElement) {
                inputElement.click();
            }
        }}>Company logo</div>
                {loading && <span className="ml-2" style={{color:"red", fontSize:"10px"}}>Uploading...</span>}

        </div>
    </div>
</div>
<div
    onClick={handleSubmitForm}
    className='mt-2'
    style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent:"center",
        alignItems:"center",
        width: "100%",
    }}
>    <div  className='flex justify-center w-[40%] items-center py-2 px-10 rounded-2xl bg-[#1068b6]'>
        <button className='  ' style={{color:"#fff",fontSize:"12px"}} >Submit</button>
    </div>
    <div className='font-light text-sm ' style={{fontSize:"10px"}}>This helps us personalize your experience.</div>
</div>
</div>
            </Box>
                    <Box sx={{width:"45%",height:"100%",display:{xs:"none",sm:"none",md:"none",lg:"flex"},flexDirection:"column",justifyContent:"end",alignItems:"end"}}>
                  
            <Box sx={{flexDirection:"row",display:"flex",justifyContent:"end",alignItems:"end"}}>
            <IconButton
      onClick={() => navigate(-1)}
      sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'gray.500',
                  }}
                >
                  <CloseIcon />
                </IconButton>  
               
  
                 <Box sx={{marginX:"20px"}} >
                    <img src={image1} alt='img'  />
                                </Box>
                                <Box sx={{marginX:"20px",marginTop:"10px"}} >
                                <img src={image2} alt='img' />
                                </Box> </Box>
                    </Box>
            </Box>
                     
              </Box>
              </Box>
    );
};

export default List;
