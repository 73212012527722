import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AWS from 'aws-sdk';
import { axiosInstance } from '../api/APIs';
import Resizer from 'react-image-file-resizer';
import drop from '../images/arrow_drop_down.svg'
import { error } from "console";
import { useSelector } from "react-redux";
import profile from '../images/profile.png'
import back from '../images/Back.svg'
import { Link } from "react-router-dom";
import { districts } from "../data";
import { CircularProgress, IconButton } from "@mui/material";
import defaultProfileIcon from '../../compounts/images/Profile2.png'; // Your default icon path
import { Modal, Box, Button,Chip } from '@mui/material';
import { MdArrowBackIosNew } from "react-icons/md";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';



AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIAXKPUZW5AH27B7EGM',
    secretAccessKey: '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',
});

const S3 = new AWS.S3();

const service = [
    { id: 1, value: "Electrician" },
    { id: 2, value: "Packers & Movers" },
    { id: 3, value: "Home Renovations" },
    { id: 4, value: "Painting" },
    { id: 5, value: "Home Interior" },
    { id: 6, value: "Construction Service" },
]

const PostService = () => {
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    console.log('User Login Data:', loginData);

    const [profileImage, setProfileImage] = useState<string>(); // Set default icon
    const [name, setName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [district, setDistrict] = useState<string>('');
    const [town, setTown] = useState<string>('');
    const [submit, setSubmit] = useState<boolean>(false);
    const [textArea, setTextArea] = useState<string>('');
    const [addTown, setAddTown] = useState<{ id: number, place: string }[]>([]);
    const [visableTitle, setVisableTitle] = useState<any>(false)
    const [businessName, setBusinessName] = useState<any>()
    const [visableDis, setVisableDis] = useState<any>(false)
    const [loading, setLoading] = useState<boolean>(false); // State for loading
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility

    const [errors, setErrors] = useState({
        name: '',
        businessName: '',
        phone: '',
        district: '',
        addTown: '',
        textArea: '',
        title: ''
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProfileImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                setLoading(true); // Start loading

                const resizedImage = await resizeImage(file);
                const fileUrl = await uploadFileToS3('vattara-img', resizedImage);
                console.log("log",fileUrl)
                setProfileImage(fileUrl);
            } catch (error) {
                console.error('Error uploading profile image:', error);
            }finally {
                setLoading(false); // Stop loading
            }

        }
    };

    const resizeImage = (file: File): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                800, // New width
                600, // New height
                'JPEG', 
                90, // Quality
                0, // Rotation
                (uri) => {
                    resolve(uri as Blob);
                },
                'blob'
            );
        });
    };

    const uploadFileToS3 = async (bucketName: string, file: Blob) => {
        const fileName = `profile_${Date.now()}`; // Use a unique name
        const params = {
            Bucket: bucketName,
            Key: fileName,
            Body: file,
            ContentType: 'image/jpeg',
        };
        try {
            const uploadResponse = await S3.upload(params).promise();
            return uploadResponse.Location;
        } catch (error) {
            throw new Error(`Error uploading ${fileName} to S3: ${error}`);
        }
    };
    const handleDeleteTown = (id: number) => {
        // Filter out the town with the specified id
        const updatedTowns = addTown.filter(town => town.id !== id);
        setAddTown(updatedTowns);
    };
    const validateFields = () => {
        let valid = true;
        const newErrors = { name: '', businessName: '', phone: '', district: '', addTown: '', textArea: '', title: '' };

        if (!name) {
            newErrors.name = 'Name is required';
            valid = false;
        }
        if (!businessName) {
            newErrors.businessName = 'Business Name is required';
            valid = false;
        }
        if (!phone) {
            newErrors.phone = 'Enter your vaild mobile number';
            valid = false;
        }
        if (!district) {
            newErrors.district = 'District is required';
            valid = false;
        }
        if (addTown.length === 0) {
            newErrors.addTown = 'Town/Locality is required';
            valid = false;
        }
        if (!textArea) {
            newErrors.textArea = 'Enter the Job Description ';
            valid = false;
        }
        if (!title) {
            newErrors.title = "Enter the your Job Title"
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    const handleTown = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTown(event.target.value)
    }

    const handleAddTown = () => {
        if (town.trim()) { // Ensure town is not empty
            const newTown = { id: addTown.length + 1, place: town };
            setAddTown([...addTown, newTown]);
            setTown(''); // Clear the input field

        }
    };

    let locality = ''
    const values = addTown.map((item) => item.place);
    for (let i = 0; i < values.length; i++) {
        locality += values[i];
        if (i < values.length - 1) {
            locality += ",";
        }
    }
    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }
        

        const mobile = `+91${phone}`

        const body = {
            user_name: name,
            mobile_no: mobile,
            service_category: title,
            business_profile: profileImage,
            district: district,
            job_description: textArea,
            locality: locality,
            business_name: businessName,
            user_id: loginData.user_id


        };

        console.log(body);

        try {
            const response = await axiosInstance.post("/services/user/create", body);
            console.log(response.data);
             setSubmit(true);
             setOpenModal(true);

        } catch (error) {
            console.log("error", error);
        }
    };

    function handlevisableTitle() {
        setVisableTitle(!visableTitle)
    }
    function handleAddTitle(value: any) {
        setTitle(value)
        setVisableTitle(false)
    }

    function handleVisableDIs() {
        setVisableDis(!visableDis)
    }
    function handleDis(value: any) {
        setDistrict(value)

    }

    return (
        <Box
        sx={{
          display: 'flex',
          // display:"flex",
          alignItems: 'center',
          justifyContent:"center",
          height:"100%",
          width:"100%",
    
        }}
      >
        <Box
          sx={{
            bgcolor: 'white',
          //   border: '0.5px solid',
            borderColor: 'gray.300',
            borderRadius: '20px',
            marginTop:{xs:"7%",sm:"7%%",md:"4%",lg:"4%"},
            marginBottom:"30px",

            mx: { xs: 1, md: 10 }, // Responsive margins
            overflowY: "hidden",
            width:{xs:"99%",sm:"90%",md:"80%",lg:"60%"},
            height:"100%",

            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            boxShadow: 3, // Add box shadow here
  
          }}
        > <Box className='flex ' sx={{marginTop:{xs:0,sm:2},display:"flex",justifyContent:"center",alignItems:"center"}}>
      <IconButton>  <button 
          onClick={() => navigate(-1)} // Inline navigation logic
        //   className="mt-4 "
        >
          <MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' />
        </button></IconButton>
        <p className='text-xl font-bold flex justify-center items-center flex-1'>Add your service</p>
      
              </Box>
        <div className={`bg-[#F5F4F8]   flex flex-col`}>
            <div className={`${submit ? "bg-none" : "bg-white"}   h-full  flex flex-col `}>
                <div className={`${submit ? "hidden" : "block msm:ml-5 md:ml-[5%]"} sm:ml-[5%] xl:mx-[15%]  xl:ml-[20%] `}>
                    {/* <div className=" md:hidden msm:flex sm:hidden items-center gap-5">

                        <Link to={'/servicesDetails'}>
                            <img className='   md:hidden msm:flex sm:hidden' src={back} />
                        </Link>
                        <p className="text-lg font-semibold">Add your service</p>
                    </div> */}
                    <div className="pt-3">
                        <p className="text-lg font-semibold   flex">Profile Information</p>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <div className="flex flex-row mt-2 items-center">
                            <label htmlFor="profile-image-upload" className="cursor-pointer">
                                <img
                                    src={profileImage || defaultProfileIcon} // Replace with a default image path if profileImage is null
                                    alt="Profile"
                                    style={{height:"60px",width:"60px",borderRadius:"40px",objectFit:"contain"}}
                                 />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleProfileImageUpload}
                                    className="hidden"
                                    id="profile-image-upload"
                                />
                            </label>
                            <p
                                onClick={() => {
                                    const inputElement = document.getElementById('profile-image-upload') as HTMLInputElement | null;
                                    if (inputElement) {
                                        inputElement.click();
                                    }
                                }}
                                className="ml-4  text-[#006CE7] font-bold cursor-pointer"
                            >
           {profileImage && profileImage !== defaultProfileIcon 
  ? "Edit Image" 
  : "Upload Business Profile"}
                            </p>

                        </div>          
                                           {loading && <CircularProgress className="ml-4 " sx={{marginTop:"-40px"}} size={24} />} {/* Show loader when uploading */}

                    </div>
                    <div className="">
                        <div className="mt-4">

                            <input
                                onChange={(e) => setName(e.target.value)}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                placeholder="Name"
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mt-4">

                            <input
                                onChange={(e) => setBusinessName(e.target.value)}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                                placeholder="Business Name"
                            />
                            {errors.businessName && <p className="text-red-500">{errors.businessName}</p>}
                        </div>
                        <div className="mt-4">

                        <div className="relative">
    <input
        type="tel" // or use type="number"
        onChange={(e) => {
            const value = e.target.value;
            // Ensure the input is a maximum of 10 digits
            if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                setPhone(value);
            }
        }}
        className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%]"
        placeholder="Contact Number"
        maxLength={10} // Limits the input to 10 characters
        pattern="[0-9]*" // This helps on mobile to show a numeric keyboard
        inputMode="numeric" // This helps on mobile to show a numeric keyboard
    />
</div>
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mt-4">
                            <p className="text=lg font-semibold mb-2">Service Information</p>
                            <div className="relative w-[90%]">

                                <input
                                    onClick={handlevisableTitle}
                                    value={title}
                                    className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl  w-[100%]"
                                    placeholder="Select your sevice"
                                />
                                <img
                                    onClick={handlevisableTitle}
                                    src={drop}
                                    alt="icon"
                                    className={`absolute top-1/2 transform -translate-y-1/2 right-4 transition-transform duration-300 ${visableTitle ? 'rotate-0' : 'rotate-90'}`}
                                />
                                <div
                                    className={`transition-all duration-1000 ease-in-out transform ${visableTitle ? "opacity-100 scale-100" : "opacity-0 scale-95 hidden"} absolute z-10 bg-white w-full rounded-2xl shadow-lg mt-2`}
                                >
                                    {service.map((item, index) => (
                                        <div key={index} onClick={() => { handleAddTitle(item.value) }} className="flex flex-col p-2">
                                            <p className="ml-2">{item.value}</p>
                                            <div className="border-1 border-[#8E8E8E] opacity-75 mx-2"></div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {errors.title && <p className="text-red-500">{errors.title}</p>}
                        </div>
                        <div className="mt-4">

                            <textarea
                                onChange={(e) => setTextArea(e.target.value)}
                                className="w-[90%]  bg-[#F5F4F8]  h-[20vh] border-none p-2 rounded-2xl placeholder:text-[#404040]"
                                placeholder="Job Description"
                            />
                            {errors.textArea && <p className="text-red-500">{errors.textArea}</p>}
                        </div>

<div className="mt-4">
            <FormControl variant="outlined"  sx={{ width: "90%" }} error={Boolean(errors.district)}>
                <InputLabel id="district-select-label" sx={{marginTop:"-5px"}}>Select District</InputLabel>
                <Select
                    labelId="district-select-label"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    label="Select District"
                    sx={{
                        backgroundColor:"#F5F4F8",
                        borderRadius:"15px",
                        height:"40px",
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Remove the border
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Remove border on hover
                        },
                    }}
                 
                >
                    {districts.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                {errors.district && <FormHelperText>{errors.district}</FormHelperText>}
            </FormControl>
        </div>
                        <div className="mt-4">

                            <input
                                type="text"
                                value={town}
                                placeholder="Town/Locality"
                                onChange={(e) => setTown(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddTown();
                                    }
                                }}
                                className="border-none placeholder:text-[#404040] bg-[#F5F4F8] p-2 rounded-2xl w-[90%] "
                            />
                            {errors.addTown && <p className="text-red-500">{errors.addTown}</p>}
                        </div>
                        <div>
            {addTown.length > 0 ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
                    {addTown.map((t) => (
                        <Chip
                            key={t.id}
                            label={t.place}
                            onDelete={() => handleDeleteTown(t.id)}
                            sx={{ margin: '4px' }}
                        />
                    ))}
                </Box>
            ) : (
                <p>No towns added.</p>
            )}
        </div>
                        <div className="flex justify-center mt-9 mb-6">
                            <p onClick={handleSubmit} className="bg-[#0CB7D1] cursor-pointer xl:py-3 msm:py-2  msm:px-10 xl:px-24 text-white rounded-3xl">Submit for Verification</p>
                        </div>
                    </div>
                </div>
                <Modal
      open={openModal}
      onClose={() => setOpenModal(false)} // Close modal on backdrop click
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width:300,
            borderRadius:"10px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: 'center'
          }}
        >
              
        <h2 style={{ margin: 0 }}>Thank You!</h2>
        <p>Our Vattara agent will verify and call you.</p>
        <Button sx={{height:"30px",width:"50px",marginTop:"20px"}} onClick={() => {
                setOpenModal(false); // Close modal
                navigate(-1); // Go back to the previous screen
            }} variant="contained" color="primary">Close</Button>
    </Box>
</Modal>

            </div>
        </div>
        </Box></Box>
    );
};

export default PostService;
