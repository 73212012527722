import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { axiosInstance } from "./api/APIs";
import ListingItem from "../compounts/ListingItem";
import { Box, Skeleton,IconButton } from '@mui/material';
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

interface Property {
  id: number;
  post_id: number;
  type: string;
  sublocality: string;
  propertyID:string;
  createdAt:string;

  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: Image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any;
  liked?: any;
  district: any
  user_id: any
  active: boolean;
  post_approval:any
}
interface ListingItemProps {
  list: Property[];
}
interface Image {
  filename: string;
  id: string;
  isCover: boolean;
  isvisible: boolean;
  name: string;
  type: string;
  uri: string;
}
const Listings: React.FC = () => {
  const [filter, setFilter] = useState<string>('Residential');
  const [list, setlist] = useState<Property[]>([]);
  const loginData = useSelector((item: any) => item.userData.userLoginData);
  const [visiable, setVisable] = useState<any>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [render, setRender] = useState<any>(false)
  const [searchQuery, setSearchQuery] = useState<string>(''); // State to hold search query
  const [filteredList, setFilteredList] = useState<Property[]>([]);  // State to store filtered list

  const [iValue, setIvalue] = useState<any>("")
  const navigate = useNavigate();


  useEffect(() => {
    handleList(filter);

  }, [filter, render]);
  console.log(loginData.user_id);


  useEffect(() => {
    // Filter the list based on searchQuery whenever it changes
    if (searchQuery.trim() === '') {
      setFilteredList(list);  // If search is cleared, show all listings
    } else {
      const filtered = list.filter(property => 
        property.propertyID.toLowerCase().includes(searchQuery.toLowerCase())||
        property.sublocality.toLowerCase().includes(searchQuery.toLowerCase())


      );
      setFilteredList(filtered);
    }
  }, [searchQuery, list]);
  

  
  async function handleList(value: any) {
    setLoading(true);
    setFilter(value);
    const body = {
      user_id: loginData.user_id,
      property_sub_type: value,
    };
    console.log(body);
  
    try {
      const response = await axiosInstance.post("/filter/myProperty", body);
      console.log(response.data.message);
  
      // Sort the fetched data by `createdAt` in descending order (newest first)
      const sortedList = response.data.message.sort((a: Property, b: Property) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA; // For descending order (newest first)
      });
  
      setlist(sortedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  



  return (
    <div className=' xl:mt-20 lg:mt-9 sm:mt-9 md:mt-10  mb-4 flex justify-center'>
<div className=" msm:w-[99%] sm:w-[99%] lg:w-[70%] xl:w-[60%] custom-lg:w-[60%] custom-xl:w-[70%] ">
      {/* <div className='flex items-center msm:mt-2 font-semibold custom-xxl:ml-[20%] custom-xl:ml-[18%] lg:ml-[12%] xxl:ml-[15%]'>
        <Link to={'/profile'}>
          <MdArrowBackIosNew className=' w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
        </Link>
        <p>Home.</p>
        <p>Profile.</p>
        <p>My Listings</p>
      </div> */}
      <Box          className="fixed h-15  z-40 bg-[#fff]  lg:mt-12  sm:mt-10 md:flex flex-col  msm:w-[99%] sm:w-[99%] lg:w-[71%] xl:w-[61%] custom-lg:w-[61%] custom-xl:w-[71%] top-0"
 >

<div className="flex-row flex items-center"> <IconButton
      onClick={() => navigate(-1)} // Inline navigation logic
      aria-label="Back"
      sx={{
        borderRadius: '50%', // Optional: for a circular button
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
        },
      }}
    >
      <MdArrowBackIos className='w-8 h-7 ' color="#000" />
    </IconButton>
    <div className="font-bold">
      My Listings
    </div></div>
<div className={`${visiable ? "hidden" : "flex my-2"} flex-col sm:flex-row`}>

<div className="flex msm:justify-center msm:items-center sm:justify-start w-full">


<div className="bg-[#F5F4F8] rounded-[30px] p-1 relative,  ">
  <div className="button-box relative flex h-auto w-full">
    <div
      id="btn"
      className={`bg-[#1063b5] rounded-[30px] absolute top-0 transition-all duration-300 ${filter === 'Commercial' ? 'left-1/2' : 'left-0'}`}
      style={{ width: '50%', height: '100%' }}
    ></div>
    <button
      type="button"
      className={`toggle-btn mr-3 relative z-10 ${filter === 'Residential' ? 'text-white rounded-l-full flex justify-center' : 'text-black hover:text-white'}`}
      onClick={() => handleList('Residential')}
      style={{ padding: '7px 45px', fontSize: '14px' }}
    >
      Residential
    </button>
    <button
      type="button"
      className={`toggle-btn relative ml-3 z-10 ${filter === 'Commercial' ? 'text-white rounded-r-full' : 'text-black hover:text-white'}`}
      onClick={() => handleList('Commercial')}
      style={{ padding: '7px 45px', fontSize: '14px' }}
    >
      Commercial
    </button>
  </div>

</div></div>
<div className="sm:flex border mx-10 mt-1 border-[#202020] rounded-3xl h-[40px] sm:ml-16 md:ml-20 ">
    <input
 value={searchQuery}
 className="rounded-3xl w-[350px] placeholder-gray-500 pl-3 "

 onChange={(e) => setSearchQuery(e.target.value)} // Handle input change    className="rounded-l-3xl w-[250px] placeholder-gray-500 pl-3 uppercase"
        placeholder="Search by Property Id, Locality"
    />
      </div>
</div>

  </Box>
    
    <div className="sm:mt-[120px] msm:mt-[140px] md:mt-[120px] xl:mt-[70px]">  
      <div className={`${visiable ? "" : ""}`}>
        {loading ?
          <div>
            <p>loading...</p>
            <Skeleton animation="wave" sx={{ height: 200 }} />
            <Skeleton animation="wave" sx={{ height: 200 }} />
            <Skeleton animation="wave" sx={{ height: 200 }} />
          </div> : 
<ListingItem list={filteredList} render={render} setRender={setRender} filter={filter} visiable={visiable} setVisable={setVisable} />}
      </div>
      </div></div>
    </div>
  );
};

export default Listings;
