import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useStepperContext } from './StepperContext';
import {
  Button,
  TextField,
  Typography,
  Box,
  InputAdornment,
} from '@mui/material';
import AutoComponent from './icon/AutoComponent';

interface TextFieldProps {
  label: string;
  formName: string;
  inputId?: number;
  value?: string;
  onChangeText?: (text: string) => void;
  required?: boolean;
  userselectedData?: any;
  initial?: string;
  label2?: any;
}

const TextAreaBox: React.FC<TextFieldProps> = (props, onNext) => {
  const {
    label,
    formName,
    inputId,
    initial = '',
    onChangeText,
    required,
  } = props;

  const [textValue, setTextValue] = useState<string>(initial);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { addUserData, userData } = useStepperContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const MAX_CHAR_COUNT = 2000;

  // Update textValue when initial prop changes
  useEffect(() => {
    setTextValue(initial);
  }, [initial]);

  // const generateParagraph = (data: any): string => {
  //   const details = data.reduce((acc: Record<string, any>, item: any) => {
  //     const entry = Object.entries(item).find(
  //       ([k]) => k !== 'formName' && k !== 'id' && k !== 'label'
  //     );
  //     if (entry) {
  //       const [key, value] = entry;
  //       acc[key] = value;
  //     }
  //     return acc;
  //   }, {});


    
  //   const propertyType = details['Property Type'];
  //   const lookingTo = details['Looking To'];
  //   const propertysubtype = details['Property Subtype'];

  //   let template = '';

  //   if (propertyType === 'Residential') {
  //     if (lookingTo === 'Rent/Lease') {
  //       template = `This is a ${details.BHK} Residential ${
  //         details['Property Subtype'] ||""
  //       } available for ${
  //         details['Property available for']||""
  //       } in a well-established construction in the prime locality of ${
  //         details.Locality||""
  //       }. The ${
  //         details['Property Subtype']||""
  //       } offers generous living space ideal for families or individuals seeking comfort and convenience. The spacious unit spans ${
  //         details['Built up Area']||""
  //       } sq ft, offering ample living space for comfort. The ${
  //         details['Property Subtype']||""
  //       } faces ${
  //         details.Facing||""
  //       }, ensuring abundant natural light and a pleasant living environment. The ${
  //         details['Property available for']
  //       } amount is set at ${details['Monthly Rent'] || ''}${
  //         details['Lease Amount'] || ''
  //       }, providing excellent value for such a desirable area.`;
  //     } else if (lookingTo === 'Sell' && propertysubtype === 'Plot / Land') {
  //       template = `Welcome to an exceptional opportunity to build your dream home on this ${details['Plot Area']||""

  //       } ${details['measure']||""  } Residential Plot. Nestled in ${details.Locality||""}. This plot faces ${details.Facing||""}, offering abundant natural light and scenic views. Priced competitively ${details['Plot Price']}, this blank canvas awaits your vision for a custom-designed residence.`;
  //     } else if (lookingTo === 'Sell') {
  //       template = `This ${details.BHK||""} Apartment is available for sale in ${details.Locality||""

  //       }. The Apartment offers a spacious living area of ${details['Built up Area']||""

  //       } Sq. ft. Experience a vibrant community lifestyle,complete with optional facilities ${details['Amenities']||""

  //       }. Take this chance to own a property at an attractive price. The Apartment faces ${details.Facing||""

  //       }, ensuring abundant natural light and a pleasant living environment. It is attractively priced at ${details.Cost||""

  //       }, with the option to negotiate the amount, making it an excellent opportunity for potential buyers to secure a desirable property in a prime location.`;
  //     }
  //   } else if (propertyType === 'Commercial') {
  //     if (lookingTo === 'Rent/Lease') {
  //       template = `Discover the ideal commercial property for ${details['Property available for']||""}, featuring spacious ${details['Property Subtype']||""

  //       } spaces ranging from ${details['Built Up Area'] || details['Built up Area'] ||""} sq ft, available at competitive rental as ${details['Lease Amount'] ||details['Monthly Rent'] 

  //       }. The property is strategically oriented to optimize natural light, with options facing ${details.Facing||""}. Situated in ${details.Locality||""

  //       }. prime locations with excellent transport links, these spaces are perfect for various business needs. Designed to foster productivity and efficiency, they offer a solid foundation for your operations. Secure your optimal ${details['Property Subtype']

  //       } today and enhance your business prospects with attractive terms.`;
  //     } else if (lookingTo === 'Sell') {
  //       template = `Explore this prime commercial property for sell in a vibrant location at ${details.Locality||""

  //       }. offering versatile spaces ${details['Property Subtype']||""

  //       }. Spanning ${details['Built up Area']||""} square feet, it boasts ${details.Facing||""

  //       } facing for optimal visibility and natural light. Priced competitively at ${details.Price||""

  //       }, it presents a compelling investment opportunity in a rapidly growing commercial hub. With modern infrastructure and strategic accessibility, this property is ideal for expanding businesses or lucrative income. Don't miss out—schedule a viewing today to seize this promising investment in ${details.Sublocality ||''}.`;
  //     }
  //   }

  //   return template;
  // };
  const formatNumber = (value: number | string): string => {
    if (typeof value === 'number' || !isNaN(Number(value))) {
      return new Intl.NumberFormat().format(Number(value));
    }
    return value.toString();
  };
  
  const generateParagraph = (data: any): string => {
    const details = data.reduce((acc: Record<string, any>, item: any) => {
      const entry = Object.entries(item).find(
        ([k]) => k !== 'formName' && k !== 'id' && k !== 'label'
      );
      if (entry) {
        const [key, value] = entry;
        acc[key] = value;
      }
      return acc;
    }, {});
  
    const propertyType = details['Property Type'];
    const lookingTo = details['Looking To'];
    const propertysubtype = details['Property Subtype'];
  
    let template = '';
  
    if (propertyType === 'Residential') {
      if (lookingTo === 'Rent/Lease') {
        template = `This is a ${details.BHK} Residential ${
          details['Property Subtype'] || ""
        } available for ${formatNumber(details['Property available for'] || "")} in a well-established construction in the prime locality of ${
          details.Locality || ""
        }. The ${
          details['Property Subtype'] || ""
        } offers generous living space ideal for families or individuals seeking comfort and convenience. The spacious unit spans ${
          details['Built up Area'] || ""
        } sq ft, offering ample living space for comfort. The ${
          details['Property Subtype'] || ""
        } faces ${details.Facing || ""}, ensuring abundant natural light and a pleasant living environment. The ${
          details['Property available for']
        } amount is set at ${formatNumber(details['Monthly Rent'] || '')}${
          formatNumber(details['Lease Amount'] || '')
        }, providing excellent value for such a desirable area.`;
      } else if (lookingTo === 'Sell' && propertysubtype === 'Plot / Land') {
        template = `Welcome to an exceptional opportunity to build your dream home on this ${details['Plot Area'] || ""}${
          details['measure'] || ""
        } Residential Plot. Nestled in ${details.Locality || ""}. This plot faces ${details.Facing || ""}, offering abundant natural light and scenic views. Priced competitively ${formatNumber(details['Plot Price'])}, this blank canvas awaits your vision for a custom-designed residence.`;
      } else if (lookingTo === 'Sell') {
        template = `This ${details.BHK || ""} Apartment is available for sale in ${details.Locality || ""}. The Apartment offers a spacious living area of ${
          details['Built up Area'] || ""
        } Sq. ft. Experience a vibrant community lifestyle, complete with optional facilities ${
          details['Amenities'] || ""
        }. Take this chance to own a property at an attractive price. The Apartment faces ${
          details.Facing || ""
        }, ensuring abundant natural light and a pleasant living environment. It is attractively priced at ${formatNumber(
          details.Cost || ""
        )}, with the option to negotiate the amount, making it an excellent opportunity for potential buyers to secure a desirable property in a prime location.`;
      }
    } else if (propertyType === 'Commercial') {
      if (lookingTo === 'Rent/Lease') {
        template = `Discover the ideal commercial property for ${details['Property available for'] || ""}, featuring spacious ${
          details['Property Subtype'] || ""
        } spaces ranging from ${details['Built Up Area'] || details['Built up Area'] || ""} sq ft, available at competitive rental as ${formatNumber(
          details['Lease Amount'] || details['Monthly Rent']
        )}. The property is strategically oriented to optimize natural light, with options facing ${details.Facing || ""}. Situated in ${details.Locality || ""}. prime locations with excellent transport links, these spaces are perfect for various business needs. Designed to foster productivity and efficiency, they offer a solid foundation for your operations. Secure your optimal ${
          details['Property Subtype']
        } today and enhance your business prospects with attractive terms.`;
      } else if (lookingTo === 'Sell') {
        template = `Explore this prime commercial property for sell in a vibrant location at ${details.Locality || ""}. offering versatile spaces ${
          details['Property Subtype'] || ""
        }. Spanning ${details['Built up Area'] || ""} square feet, it boasts ${details.Facing || ""} facing for optimal visibility and natural light. Priced competitively at ${formatNumber(
          details.Price || ""
        )}, it presents a compelling investment opportunity in a rapidly growing commercial hub. With modern infrastructure and strategic accessibility, this property is ideal for expanding businesses or lucrative income. Don't miss out—schedule a viewing today to seize this promising investment in ${details.Sublocality || ''}.`;
      }
    }
  
    return template;
  };
  

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setTextValue(value);
    const data = {
      id: inputId,
      [label]: value,
      formName,
      required,
    };
    addUserData(data as any);
    onChangeText?.(value);
  };

  const handleAutoGenerate = () => {
    const paragraph = generateParagraph(userData);
    setTextValue(paragraph);
    const data = {
      id: inputId,
      [label]: paragraph,
      formName,
      required,
    };
    addUserData(data as any);
    inputRef.current?.blur();
  };

  const handleTextChange = (text: string) => {
    if (text.length <= MAX_CHAR_COUNT) {
      setTextValue(text);
      handleChange({ target: { value: text } } as ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement
      >);
      onChangeText?.(text);
    }
  };

  return (
    <div>
      <TextField
        multiline
        inputProps={{
          maxLength: MAX_CHAR_COUNT,
          style: {
            fontFamily:"K2D",
            marginTop: '13px',
            height:"150px" // Adjusts the text position from the top
          },
        }}
        variant="outlined"
        id="textField"
        placeholder="Enter text here"
        label="Detail About Your Property"
        className="w-[100%]  mt-2"
        value={textValue}
        onFocus={() => setIsFocused(true)}
        helperText={required && !textValue ? 'This field is required' : ''}
        error={required && !textValue} // Show error if required field is empty
        onChange={(event) => handleTextChange(event.target.value)}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  fontSize: '0.7rem',
                  padding: '4px 8px',
                  position: 'absolute',
                  right: '10px',
                  top: '5px',
                }}
              >
                <AutoComponent onClick={handleAutoGenerate} />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ mt: 1 }}>
        <Typography variant="caption" color="textSecondary">
          {textValue.length}/{MAX_CHAR_COUNT} characters
        </Typography>
      </Box>
    </div>
  );
};

export default TextAreaBox;
