import React, { useState } from 'react'
import down from '../images/down arrow.svg'
import { Link } from 'react-router-dom';
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';

 
function HelpCentermobile() {
    const navigate = useNavigate();

    const faq = [
        {
            src: down,
            question: 'What is Vattara?',
            ans: 'Vattara is a comprehensive brokerage platform that facilitates the buying, selling, renting, and leasing of properties through an intuitive website and app. It connects users with property owners, making it easier to find your ideal home or investment opportunity.'
        },
        {
            src: down,
            question: 'How do I post a property?',
            ans: 'Click the "Post Property" button, complete all required fields with the necessary details, and submit your listing for review.'
        },
        {
            src: down,
            question: 'Is there a fee to post a property?',
            ans: 'No, there are no fees associated with posting a property.'
        },
        {
            src: down,
            question: 'How long does it take for my property to be listed?',
            ans: 'The Vattara officials will verify the property details, and the listing process typically takes up to 24 hours.'
        },
        {
            src: down,
            question: 'What additional services does Vattara provide? ',
            ans: 'Vattara offers a variety of additional services, including packers and movers, home renovation, electrical services, construction, painting, and home interior design.'
        },
        {
            src: down,
            question: 'How do I contact a property owner?',
            ans: ' You can contact the property owner by clicking the "Contact" button on the property page, which allows you to reach out via the chat option.'
        },
        {
            src: down,
            question: 'Can I message the property owner through the app?',
            ans: 'Yes, you can message the property owner using the "Contact" button, which allows you to chat for additional details.'
        },
        {
            src: down,
            question: 'Can multiple users log in to the same account?',
            ans: 'Yes, our platform allows up to three users to log in to a single account simultaneously.'
        },
        {
            src: down,
            question: 'How do I edit or delete my property listing?',
            ans: `To edit or delete your property listing, go to your profile page and select the "My Listings" option, where you can view all the properties you've posted and make any necessary changes.`
        },
        {
            src: down,
            question: 'How do I report inappropriate content or behavior?',
            ans: ` You can report any inappropriate content or behavior using the "Report" option available on our platform, or you can reach out to our customer support team for assistance.`
        },
        {
            src: down,
            question: ' How do I update my profile information?',
            ans: `You can update your profile information by navigating to the profile section and editing your details; please note that the mobile number cannot be changed.`
        },
        {
            src: down,
            question: ' What languages is Vattara available in? ',
            ans: `Vattara is available in Tamil and English.`
        },
        {
            src: down,
            question: ' Are there any age restrictions for using Vattara? ',
            ans: `Yes, users must be 18 years or older to use Vattara `
        },
        {
            src: down,
            question: ' How do I report a bug or technical issue?',
            ans: `You can report any bugs or technical issues by updating the relevant details on the ratings page.`
        },
        {
            src: down,
            question: 'Can I save my favorite property?',
            ans: `Yes, you can save your favorite properties, and they will be listed in your saved properties section.`
        },
        {
            src: down,
            question: 'How do I provide feedback on app features? ',
            ans: `You can provide feedback on app features by using the ratings page to submit your comments.`
        },
        {
            src: down,
            question: 'What happens if a property is sold, rented, or leased?',
            ans: ` Owners are responsible for deleting the property from the listings once it is sold, rented, or leased.`
        },
        {
            src: down,
            question: 'What is the Terms of Service for Vattara?',
            ans: `The Terms of Service for Vattara are detailed on the Terms and Service page, which can be found in the settings section.`
        },
        {
            src: down,
            question: 'What is the Privacy Policy for Vattara? ',
            ans: `The Privacy Policy for Vattara is detailed on the Privacy and Policy page, which you can find in the settings `
        },
        {
            src: down,
            question: ' Is user data sold to third parties?  ',
            ans: `No, user data is not sold to third parties.`
        }
    ]

    const [open, setopen] = useState(null)
    const [rotation, setRotation] = useState(Array(faq.length).fill(0));


    const handleView = (index) => {
        setopen(open === index ? null : index)
        setRotation(prev => {
            const newRotation = [...prev];
            newRotation[index] = newRotation[index] === 0 ? 180 : 0;
            return newRotation;
        });
    }

    return ( 
        <div className=' '>

            <div className='msm:ml-2 lg:ml-24 lg:mr-24 bg-white h-fit'>
       <div className=' lg:ml-11 lg:mt-[6%] sm:mt-[13%] msm:mt-[13%]'>
                   
                    <h1 className='py-1 font-bold text-lg'>FAQ'<span className='text-base'>S</span> </h1>

                </div>
                <div>
                    {faq.map(function (item, index) {
                        return (
                            <div key={index}>
                                <div className=' lg:ml-11 msm:ml-5 '>
                                    <div className=' gap-5 flex items-center mb- cursor-pointer' onClick={() => handleView(index)}>
                                        <img
                                            className={`h-fit mt- transition-transform duration-300 `}
                                            style={{ transform: `rotate(${rotation[index]}deg)` }}

                                            src={item.src}
                                            alt=""
                                        />

                                        <h1 className='msm:mr-3 font-semibold ' >{item.question}</h1>

                                    </div>
                                    {open === index && (
                                        <h1 className='mb-4 mt-2 lg:mr-0 msm:mr-6'>{item.ans}</h1>

                                    )}
                                    <div className='border border-x-8 mb-2 lg:mr-0 mt-2 msm:mr-4'></div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HelpCentermobile 