import { useState } from 'react';
import { Button, Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import EditComponent from '../Icons/Edit';
import DeleteComponent from '../Icons/Delete';
import image from '../assets/Rectangle 477.png';
import image1 from '../assets/Rectangle 4771.png';
import PeopleComponent from '../Icons/People';
import deleteIcon from '../compounts/images/Delete.svg';
import edit from '../compounts/images/Edit.svg';
import People from '../compounts/images/icon_peoples.png';
import Listings from './Listings';
import arrow from '../compounts/images/arr.svg'
import { useSelector } from "react-redux";
import { axiosInstance } from "./api/APIs";
import { useLocation, useNavigate } from 'react-router-dom';
import DeletePopUp from './homepage/DeletePopUp';
import listing from "../compounts/images/myListing.png";
import listing2 from "../compounts/images/mylisting2.png";

import { truncateString } from "./ReuseableCompounts/TruncateString";
import { formatPrice } from "../compounts/ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "./ReuseableCompounts/PriceSQFormat";
import Default from "./images/defaultScroll.svg";
import { handleChange } from "../compounts/ReuseableCompounts/PostDetail";
import { useDispatch } from 'react-redux';
import { setUsers } from '../slices/filterSlice';
import pending from '../../src/images/pending.png'



interface Property {
    id: number;
    post_id: number;
    type: string;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    sublocality: string;
    propertyID:string;
    
createdAt:string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    district: any
    user_id: any
    active: boolean;
    post_approval: any
}

interface ListingItemProps {
    list: Property[];
    filter: string;
    setVisable: any
    visiable: any
    setRender: any
    render: any
}
interface Image {
    filename: string;
    id: string;
    isCover: boolean;
    isvisible: boolean;
    name: string;
    type: string;
    uri: string;
}

const ListingItem: React.FC<ListingItemProps> = ({ list, filter, setVisable, visiable, setRender, render }) => {


    const [id, setId] = useState<any>("")
    const [Type, setType] = useState<any>("")
    const [look, setLook] = useState<any>("")
    const dispatch = useDispatch();
    console.log(list);
    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);


    const navigate = useNavigate()



    async function handleNavigate(propertyView: any) {

        const body = {
            post_id: propertyView.postId,
            selected_property_type: propertyView.property_type,
            looking_to: propertyView.looking,
            user_id: propertyView.userId
        };
        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);

            dispatch(setUsers([response.data]));
            navigate('/editform', { state: response.data });
        } catch (error) {
            console.error('Error fetching data:', error);
        }


    }


    async function handleContactView(id: any) {
        const url = `/filter/contact/userData/get/${id}`
        console.log(url);


        try {
            const response = await axiosInstance.get(url)
            console.log(response.data.message)
            navigate('/contactUser', { state: { data: response.data.message } })

        } catch (error) {
            console.log("error", error)
        }
    }
    function handleDalete(Id: any, type: any, Look: any) {
        setVisable(true)
        setId(Id)
        setLook(Look)
        setType(type)
        setDeleteModalOpen(true); // Open the modal


    }
    console.log("delete", id, look, Type)
    // const handleChange = async (id: number, property: string, looking_to?: string) => {
    //     const body = {
    //         post_id: id,
    //         selected_property_type: property,
    //         looking_to: looking_to || ''
    //     };
    //     try {
    //         const response = await axiosInstance.post("/filter/get/postDetail", body);
    //         navigate('/PropertyDetails', { state: { data: response.data, look: looking_to } });
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const handleChange = async (id: number, property: string, looking_to?: string) => {
        const body = {
            post_id: id,
            selected_property_type: property,
            looking_to: looking_to,
            user_id:loginData?.user_id
        };

        try {
            const response = await axiosInstance.post("/filter/get/postDetail", body);
            navigate('/myListingView', { state: { data: response.data, look: looking_to } })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // msm:mx-1 sm:mx-1 lg:mx-[10%] xl:mx-[10%] custom-lg:mx-[10%] custom-xl:mx-[20%] 
    return (
        <div>
            <div className={`${visiable ? "hidden" : "grid msm:grid-cols-1  sm:grid-cols-2  md:grid-cols-2 custom-xl:grid-cols-3 msm:gap-2  sm:gap-3 md:gap-3 msm:mx-[1%] sm:mx-[1%] lg:mx-[0%]"}`}>
                {Array.isArray(list) ? (
                    list.map((item) => {
                        const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || (item.monthly_rent ? `${item.monthly_rent} / Month` : null);
                        const formattedPrice = formatPrice(price);
                        const locality = truncateString(item.city, 38)
                        const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                        const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                        const priceSQTV = formatSQPrice(sqtv);
                        const propertyTypeDisplay = item.property_type === "Plot / Land" ? "Plot" : item.property_type;
                        const formatDate = (dateString: string): string => {
                            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
                            return new Date(dateString).toLocaleDateString(undefined, options);
                        };
                        
                        const getBackgroundColor = (value: string): string => {
                            switch (value) {
                                case 'rent':
                                    return 'bg-[#FBB337]'; // Tailwind CSS red class
                                case 'lease':
                                    return 'bg-[#4D9BF4]'; // Tailwind CSS green class
                                case 'sale':
                                    return 'bg-[#7DAE3F]'; // Tailwind CSS blue class
                                default:
                                    return 'bg-[#7DAE3F]'; // Fallback color if none match
                            }
                        };

                        const status = item.looking_to?.toLowerCase() || item.rent_or_lease?.toLowerCase() || item.property_available_for?.toLowerCase() || '';

                        const propertyView = {
                            postId: item.post_id,
                            property_type: item.property_type,
                            looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                            userId: loginData.user_id
                        }


                        return (
                            <div
                                key={item.post_id}
                                className={`bg-[#F5F4F8] ${item.active == false ? "opacity-50" : ""} shadow-md rounded-[10px] cursor-pointer  msm:w-[100%] lg:w-[100%] flex flex-col p-1 `}
                            >
                                <div className="flex w-full">
                          <div className='h-[100px] w-[23%]'>  <img
                                        src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : Default}
                                        onClick={() => { handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }}
                                        // className="md:w-32 msm:w-24 msm:h-24 md:h-32 object-cover rounded-lg"
                                    className='w-full h-full object-cover rounded-[10px]'
                                    /></div>
                                   <div  className='w-[69%]'> <div
                                        onClick={() => {
                                            if (item.active) {
                                                handleChange(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for)
                                            }
                                        }}
                                        className="flex flex-col ml-3"
                                    >
<div className="font-semibold lg:text-[14px] sm:text-[14px] msm:text-[14px] xl:text-[15px] overflow-hidden overflow-ellipsis  line-clamp-1">
{/* {item.bhk} {item.property_type} */}
                                            {item.bhk} {propertyTypeDisplay} for {item.looking_to}{item.property_available_for} in {item.sublocality}

                                        </div>
                                        <div className="text-[#8E8E8E] mt-1 text-[14px]lg:text-[14px] sm:text-[14px] msm:text-[14px] xl:text-[15px] h-[40px] overflow-ellipsis line-clamp-2">
    {item.building_name ? `${item.building_name}, ` : ''}
    {locality}
</div>

                                        <div className="flex lg:gap-3 items-center mt-1">
                                            <div className="text-[#404040] msm:text-[14px] sm:text-[14px] lg:text-[14px]">
                                                ₹ {item.monthly_rent ? `${formattedPrice} / Month` : formattedPrice}
                                            </div>
                                            <div className="mx-1 border-l h-3 border-black"></div>
                                            <div className="  msm:text-[14px] sm:text-[14px] lg:text-[14px]  text-[#8E8E8E]">
                                                {item.built_up_area}  sq.ft.
                                            </div>
                                        </div>
                                        <div className="flex lg:gap-1 items-center mt-1">
                                        <div className="msm:text-[11px] sm:text-[11px] lg:text-[12px] font-bold ">
                                            Property Id : {item.propertyID} 
                                            </div>                                             <div className="mx-1 border-l h-3 border-black"></div>
                                            <div className="text-[#404040] msm:text-[11px] sm:text-[11px] lg:text-[12px] font-bold">
                                            Created On : {formatDate(item.createdAt)}
                                            </div>
                                          
                                        </div>
                                    </div></div>
                                    
                                    <div  className='w-[8%]'><div className="flex flex-col justify-end  items-end ml-auto mb-1">
                                        <IconButton
                                            onClick={() => handleNavigate(propertyView)}
                                            disabled={!item.active}
                                            size="small"
                                        >
                                            <img className='w-9 h-9' src={edit} />
                                        </IconButton>
                                        <IconButton
                                            disabled={!item.active}
                                            onClick={() => { handleDalete(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for) }} size="small" className="ml-1">
                                            <img className='w-9 h-9' src={deleteIcon} />
                                        </IconButton>
                                    </div></div>
                                </div>
                            
                                            <div className="border  mt-1"></div>

                                <div className=" flex-row flex w-[100%] pl-1 ">
                                   <div className=" w-[20%] flex justify-center items-center "> <div

className={`text-center font-medium text-[15px] flex items-center px-3 py-1 my-1 h-7 rounded-3xl ${getBackgroundColor(status)}`}
style={{
                                            color: 'black',
                                        }}
                                    >
                                        {item.looking_to || item.rent_or_lease || item.property_available_for}
                                    </div></div>
                                    <div className={`${item.post_approval === "Approved" ? "flex" : "hidden"} w-[40%] justify-center items-center`}>
                                        <IconButton size="small">
                                            <PeopleComponent className='w-4 h-4' />
                                        </IconButton>
                                        <div className="msm:text-[13px] sm:text-[10px] lg:text-[14px] font-semibold">
                                            {item.view_post_count} Peoples Watched
                                        </div>
                                    </div>
                                    <div className={`${item.post_approval === "Approved" ? "flex" : "hidden"} w-[40%]  justify-center items-center `}>
                                        <IconButton onClick={() => { handleContactView(item.post_id) }}
                                         className='flex  items-center gap-1'
                                         >
                                            <img src={arrow} className='w-4 h-4'
                                             />
                                            <div className="text-[#006CE7] msm:text-[13px] sm:text-[10px] lg:text-[14px] "> Peoples Contacted you</div>
                                        </IconButton>
                                    </div>
                                    <div className={`${item.post_approval === "Rejected" || item.post_approval ===  "Reject" ? "flex" : "hidden"} w-[60%] justify-center items-center`}>
                                        <p>Rejected</p>
                                    </div>

                                    <div className={`${item.post_approval === "Pending" ? "flex gap-2 items-center mr-20" : "hidden"} w-[60%] justify-center items-center`}>
                                        <img className='w-5 h-5' src={pending}/>
                                        <p>Pending Approval</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div >
                    {/* Image for desktop */}
                    <img
                        className="absolute top-1/2 left-1/2 w-[40%] h-[70%] transform -translate-x-1/2 -translate-y-1/2 msm:hidden sm:hidden md:flex"
                        src={listing}
                        alt="Listing"
                    />
                    {/* Image for mobile */}
                    <img
                        className="absolute top-1/2 left-1/2 w-[75%] h-[70%] transform -translate-x-1/2 -translate-y-1/2 flex msm:flex sm:flex md:hidden"
                        src={listing2}
                        alt="Listing 2"
                    />
                </div>
                )}
            </div>
            <div className={`${visiable ? "block" : "hidden"} w-full justify-center items-center`}>
                <DeletePopUp look={look} id={id} property={Type} setVisable={setVisable} setRender={setRender} render={render} />
            </div>
        </div>

    );
};

export default ListingItem