// export const formatSQPrice = (price: string | number): string => {
//     if (typeof price === 'number') {
//       return valueFormat(price);
//     }
  
//     const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    
//     if (isNaN(numericPrice)) {
//       return 'Invalid Price'; // Handle cases where parsing fails
//     }
  
//     return valueFormat(numericPrice);
//   };
  

  
//   function valueFormat(value: number): string {
//     if (value >= 1000000000) {
//       return (value / 1000000000).toFixed(2) + ' B';
//     }
//     if (value >= 10000000) {
//       return (value / 10000000).toFixed(2) + ' Cr';
//     }
//     if (value >= 1000000) {
//       return (value / 1000000).toFixed(2) + ' M';
//     }
//     if (value >= 100000) {
//       return (value / 100000).toFixed(2) + ' Lac';
//     }
//     if (value >= 1000) {
//       return (value / 1000).toFixed(2) + ' K';
//     }
//     return value.toString();
//   }

export const formatSQPrice = (price: string | number): string => {
  let numericPrice: number;

  // If it's a string, parse it
  if (typeof price === 'string') {
    numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    if (isNaN(numericPrice)) {
      return 'Invalid Price'; // Handle cases where parsing fails
    }
  } else {
    // If it's already a number, use it directly
    numericPrice = price;
  }

  // Round the value (round to nearest integer or specific decimal places)
  numericPrice = Math.round(numericPrice); // For rounding to nearest integer
  // Or to round to 2 decimal places, you can use:
  // numericPrice = Math.round(numericPrice * 100) / 100; // For two decimal places

  return valueFormat(numericPrice); // Pass the rounded value to the valueFormat function
};

export const formatSQPrice2 = (price: string | number): string => {
  const valueFormat = (value: number) => {
    if (value >= 10000) {
      return (value / 1000).toFixed(1) + 'k'; // Convert to "k" format
    }
    return value.toString();
  };

  if (typeof price === 'number') {
    return valueFormat(price);
  }

  const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));

  if (isNaN(numericPrice)) {
    return 'Invalid Price'; // Handle cases where parsing fails
  }

  return valueFormat(numericPrice);
};

export const formatSQPrice3 = (price: string | number): string => {
  const valueFormat = (value: number) => {
    if (value >= 100000) {
      return (value / 1000).toFixed(1) + 'l'; // Convert to "k" format
    }
    return value.toString();
  };

  if (typeof price === 'number') {
    return valueFormat(price);
  }

  const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));

  if (isNaN(numericPrice)) {
    return 'Invalid Price'; // Handle cases where parsing fails
  }

  return valueFormat(numericPrice);
};

function valueFormat(value: number): string {
  if (value >= 1000000000) {
    return Math.round(value / 1000000000) + ' B';
  }
  if (value >= 10000000) {
    return Math.round(value / 10000000) + ' Cr';
  }
  if (value >= 1000000) {
    return Math.round(value / 1000000) + ' M';
  }
  if (value >= 100000) {
    return Math.round(value / 100000) + ' Lac';
  }
  if (value >= 1000) {
    return Math.round(value / 1000) + ' K';
  }
  return value.toString();
}
