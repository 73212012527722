export const tndistrict = {
  districts: [
    { id: 1, title: 'Ariyalur', name_tamil: 'அரியலூர்' },
    { id: 2, title: 'Chengalpattu', name_tamil: 'செங்கல்பட்டு' },
    { id: 3, title: 'Chennai', name_tamil: 'சென்னை' },
    { id: 4, title: 'Coimbatore', name_tamil: 'கோயம்புத்தூர்' },
    { id: 5, title: 'Cuddalore', name_tamil: 'கடலூர்' },
    { id: 6, title: 'Dharmapuri', name_tamil: 'தர்மபுரி' },
    { id: 7, title: 'Dindigul', name_tamil: 'திண்டுக்கல்' },
    { id: 8, title: 'Erode', name_tamil: 'ஈரோடு' },
    { id: 9, title: 'Kallakurichi', name_tamil: 'கள்ளக்குறிச்சி' },
    { id: 10, title: 'Kanchipuram', name_tamil: 'காஞ்சிபுரம்' },
    { id: 11, title: 'Karur', name_tamil: 'கரூர்' },
    { id: 12, title: 'Krishnagiri', name_tamil: 'கிருஷ்ணகிரி' },
    { id: 13, title: 'Madurai', name_tamil: 'மதுரை' },
    { id: 14, title: 'Mayiladuthurai', name_tamil: 'மயிலாடுதுறை' },
    { id: 15, title: 'Nagapattinam', name_tamil: 'நாகப்பட்டினம்' },
    { id: 16, title: 'Kanniyakumari', name_tamil: 'நாகர்கோயில்' },
    { id: 17, title: 'Namakkal', name_tamil: 'நாமக்கல்' },
    { id: 18, title: 'Perambalur', name_tamil: 'பெரம்பலூர்' },
    { id: 19, title: 'Pudukkottai', name_tamil: 'புதுக்கோட்டை' },
    { id: 20, title: 'Ramanathapuram', name_tamil: 'ராமநாதபுரம்' },
    { id: 21, title: 'Ranipet', name_tamil: 'ராணிப்பேட்டை' },
    { id: 22, title: 'Salem', name_tamil: 'சேலம்' },
    { id: 23, title: 'Sivaganga', name_tamil: 'சிவகங்கை' },
    { id: 24, title: 'Tenkasi', name_tamil: 'தென்காசி' },
    { id: 25, title: 'Thanjavur', name_tamil: 'தஞ்சாவூர்' },
    { id: 26, title: 'Theni', name_tamil: 'தேனி' },
    { id: 27, title: 'Thiruvallur', name_tamil: 'திருவள்ளூர்' },
    { id: 28, title: 'Thiruvarur', name_tamil: 'திருவாரூர்' },
    { id: 29, title: 'Thoothukudi', name_tamil: 'தூத்துக்குடி' },
    { id: 30, title: 'Tiruchirappalli', name_tamil: 'திருச்சி' },
    { id: 31, title: 'Tirunelveli', name_tamil: 'திருநெல்வேலி' },
    { id: 32, title: 'Tirupattur', name_tamil: 'திருப்பத்தூர்' },
    { id: 33, title: 'Tiruppur', name_tamil: 'திருப்பூர்' },
    { id: 34, title: 'Tiruvannamalai', name_tamil: 'திருவண்ணாமலை' },
    { id: 35, title: 'Nilgiris', name_tamil: 'உதகமண்டலம்' },
    { id: 36, title: 'Vellore', name_tamil: 'வேலூர்' },
    { id: 37, title: 'Viluppuram', name_tamil: 'விழுப்புரம்' },
    { id: 38, title: 'Virudhunagar', name_tamil: 'விருதுநகர்' }
  ],
};