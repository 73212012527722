import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import CustomisedButton from './CustomisedButton';
import { useStepperContext } from './StepperContext';
import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import TextBox from './TextBox';
import { basic_details } from '../data';
import DropBox from './DropBox';
import DropDownBox from './DropDownBox';
import CustomisedButton1 from './CustomisedButton1';
import { tndistrict } from './index';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import { axiosInstance } from '../../api/APIs';
import { useSelector } from 'react-redux';

const icons = {
  ApartmentIcon: <ApartmentIcon />,
  BusinessIcon: <BusinessIcon />,
};

interface NextProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const Basic: React.FC<NextProps> = ({ onNext, onBack, onReset }) => {
  const { userData } = useStepperContext(); // Access currentPage from StepperContext
  const loginData = useSelector((state: any) => state.userData.userLoginData);

  const [userselectedData, setuserSelectedData] = useState([]);
  const navigate = useNavigate()


  const [openModal, setOpenModal] = useState(false);
async function handlehelp() {

    try {
      const response = await axiosInstance.post("/user/UserHelp/Update",{user_id:loginData.user_id})
      console.log(response)
    } catch (e) {

    }
  }
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    handlehelp();
    handleCloseModal();
  };
  const generateValidationSchema = (basic_details: any, userData: any) => {
    // Filter required labels from form fields
    const requiredLabels = basic_details.fields
      .filter((field: any) => field.required)
      .map((field: any) => field.label);
    console.log('require', requiredLabels);
    // const missingLabels=requiredLabels
    // console.log('Missing Labels:', missingLabels);
    const existingLabels = requiredLabels.filter(
      (label: any) =>
        // Check if any object in userData has a property matching the current label
        !userData.some((item: any) => item.hasOwnProperty(label))
    );
    console.log('exist', existingLabels);

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const initialValues = {
    selectedPropertyType: '',
    lookingTo: '',
    city: '',
    locality: '',
    landMark: '',
  };

  const getInitialValue = (label: any) => {
    const entry = userData.find((obj) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  return ( 
    <div className="w-[100%] p-2  gap-2 custom-scrollbar">
<Box sx={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
  <IconButton 
    onClick={() => navigate(-1)} // Inline navigation logic
    sx={{ marginRight: "8px" }} // Space between the icon and the text
  >
    <MdArrowBackIosNew className='w-5 h-5' />
  </IconButton>
  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
      Basic Details
    </Box>
  </Box>
  <div>

    <button   className="block sm:hidden" // This makes it visible on mobile and hidden on larger screens

                onClick={handleOpenModal}
                style={{ width: '100px',backgroundColor: 'transparent', color: '#FBB337', borderRadius: '8px', fontFamily: 'K2D', marginTop: '10px', border: '1px solid #FBB337',fontSize:"11px" }}
              >  <div style={{color:"#000",fontSize:"8px",fontWeight:"bold",}} >
              Need Help ?
            </div>
                Connect with us
              </button></div>
</Box>

      <Formik
    
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          generateValidationSchema(basic_details, userData);

          console.log(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <Form  onSubmit={handleSubmit} className="form custom-scrollbar lg:mx-[10%] msm:mx-[1%]   overflow-Y-scroll">
            {basic_details.fields.map((basicItem, basicIndex) => (
              <div key={basicIndex}>
                {basicItem.type === 'button' && (
                  <div className=" mt-2 ">
                    <Typography                       sx={{fontSize:"15px" }}
 className="text-start gap-28  ">
                      {basicItem.label}
                    </Typography>
                    <div className="flex w-[100px]">
                      <CustomisedButton1
                        data={basicItem.data}
                        label={basicItem.label}
                        initial={getInitialValue(basicItem.label)}
                        inputId={basicItem.inputId}
                        formName={'Basic Details'}
                        required={basicItem.required}
                        userselectedData={userselectedData}
                      />
                    </div>
                  </div>
                )}
                {basicItem.type === 'DropDownBox' && (
                  <div className="w-[100%] mt-2">
                    <Typography
                      sx={{ marginY: '12px' ,fontSize:"15px"}}
                      className="text-start w-[100%]  gap-28"
                    >
                      {basicItem.label}
                    </Typography>
 
                    <DropDownBox
                      label={basicItem.label}

  // label={basicItem.label === "Property Subtype" ? "select property type" : basicItem.label}
  options={basicItem.data}
                      // title={basicItem.title}
                      inputId={basicItem.inputId}
                      initial={getInitialValue(basicItem.label)}
                      formName={'Basic Details'}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'DropDown' && (
                  <div>
                    <Typography
                      sx={{fontSize:"15px" , marginY: '12px'}}
                      className="text-start w-[100%] mt-2"
                    >
                      {basicItem.title}
                    </Typography>

                    <DropBox
                      label={basicItem.label}
                      options={tndistrict.districts}
                      // title={basicItem.title || ''}
                      inputId={basicItem.inputId}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'textField' && (
                  <div className="my-2">
                    <TextBox
                      label={basicItem.label}
                      inputId={basicItem.inputId}
                      // defaultValue={initialValues.locality}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
              </div>
            ))}
<Box className=" w-full flex justify-center items-center">
            <button
              type="submit"
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]  mt-6 custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Continue
            </button></Box>
          </Form>
        )}
      </Formik>
      <Dialog open={openModal} onClose={handleCloseModal} PaperProps={{ style: { borderRadius: 20 } }}>
        <DialogTitle sx={{fontSize:"15px",fontFamily:"K2D"}}>Connect with Us</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily:"K2D"}}>
            Would you like to continue? We would be happy to assist you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Cancel</Button>
          <Button onClick={handleConfirm} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Basic;
