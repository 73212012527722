// // Chatlist.tsx
// import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import io from 'socket.io-client';
// import { useNavigate } from 'react-router-dom';
// import { useChat } from '../context/ChatContext';
// import { Avatar, Box, IconButton } from '@mui/material';
// import { MdArrowBackIosNew } from 'react-icons/md';
// import nochat from '../../compounts/images/nochats.png'


// // const ENDPOINT = 'https://chat.vattaram.in';
// const ENDPOINT = 'https://chat.vattaram.in';

// let socket: any;

// interface User {
//   company_name: string ;
//   company_logo: string ;
//   user_id: string;
//   name: string;
//   user_pic: string;
//   email: string;
// }

// interface Sender {
//   name: string;
//   user_pic: string;
//   email: string;
//   user_id: string;
// }

// interface LatestMessage {
//   content: string;
//   createdAt: string;
//   sender: Sender;
//   chatId: number;
// }

// interface UserType {
//   chatId: number;
//   users: User[];
//   latestMessage: LatestMessage | null;
// }

// interface ChatlistProps {
//   fetchAgain?: any;
// }

// const Chatlist: React.FC<ChatlistProps> = ({ fetchAgain }) => {
//   const [users, setUsers] = useState<UserType[]>([]);
//   const loginData = useSelector((state: any) => state.userData.userLoginData);
//   const userid = loginData.user_id;
//   const navigate = useNavigate();
//   const { setSelectedChat } = useChat(); // Access setSelectedChat from context


  
//   useEffect(() => {
//     socket = io(ENDPOINT);
//     fetchChats();

//     socket.on('connect', () => {
//       socket.emit('join', userid);
//     });

//     socket.on('update last message', (newMessage: any) => {
//       console.log('New message received:', newMessage);
//       fetchChats();
//     });

//     return () => {
//       socket.off('update last message');
//       socket.disconnect();
//     };
//   }, [userid, fetchAgain]);

//   const fetchChats = async () => {
//     try {
//       const response = await axios.get(`${ENDPOINT}/api/chat/${userid}`);
//       setUsers(response.data);
//       console.log(response.data);
//     } catch (error) {
//       console.error('Error fetching chat list: ', error);
//     }
//   };

//   const handleChatClick = (item: UserType) => {
//     const user = item.users[0];
//     console.log('check the item', item);
//     setSelectedChat({
//       chatId: item.chatId,
//       userId: user.user_id,
//       username: user.name,
//       userpic: user.user_pic,
//       companylogo: user.company_logo,
//       companyname: user.company_name,

//     });
//   };

//   return (
    
//       <div  className='custom-scrollbar msm:h-[84vh] sm:h-[80vh] lg:h-[80vh] xl:h-[85vh] overflow-auto lg:px-3 lg:py-2 sm:px-2 sm:py-2  msm:px-2 msm:py-2  '>
//            {/* <Box          className="fixed w-full h-15 left-1 z-40 bg-[#fff]  items-center justify-start top-0 msm:mt-0 sm:mt-0 lg:mt-[40px]"
//  >

//  <IconButton
//       onClick={() => navigate(-1)} // Inline navigation logic
//       aria-label="Back"
//       sx={{
//         borderRadius: '50%', // Optional: for a circular button
//         '&:hover': {
//           backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
//         },
//       }}
//     >
//       <MdArrowBackIosNew className='w-8 h-7' />         <h1 >All Chats</h1>

//     </IconButton>
//   </Box> */}
//         {users.length > 0 ? (
//           users.map((item) => {
//             const user = item.users[0];
//             return (
//               <div className='gap-3 w-full'
//                 key={item.chatId}
//                 style={styles.boxContainer}
//                 onClick={() => handleChatClick(item)}
//               >
//                 <div className='w-[10%]'>
//                 {user.company_logo ? (
//             <Avatar alt={user.name} src={user.company_logo} sx={{bgcolor:"#fff",marginRight: 2, height: '40px',
//               width: '40px',
//               borderRadius: '50%',
//               borderColor: 'white',}}
// />
//         ) : (
//           <Box
//           className=' rounded-full w-16 h-16 flex items-center justify-center font-bold '
//           sx={{bgcolor:"#0CB7D1",marginRight: 2, height: '40px',
//             width: '40px',
//             borderRadius: '50%',
//             fontSize:"17px",
//             borderColor: 'white',}}            >
//   {user.company_name ? user.company_name.charAt(0) : user.name.charAt(0)}
//   </Box>
//         )}
        
//             </div>  
                
//                 <div  className='w-[74%]'>
//                 {user.company_name ? (
//                                           <div
//                                              className='msm:text-[15px] sm:text-[15px] lg:text-[13px] xl:text-[14px] font-bold  capitalize overflow-hidden whitespace-nowrap text-ellipsis '>
// {user.company_name}</div>
                 
//         ) : (
//           <div 
//             className='msm:text-[15px] sm:text-[15px] lg:text-[13px] xl:text-[14px]  font-bold  capitalize overflow-hidden whitespace-nowrap  text-ellipsis '>
// {user.name}</div>

//         )}
                 
//                   <div className='msm:text-[14px] sm:text-[14px] lg:text-xs mt-1 capitalize overflow-hidden whitespace-nowrap text-ellipsis'>
//                   {item.latestMessage?.content || 'No message yet'}               </div>
                
//                 </div> 
//                 <span style={styles.time } className=' w-[16%] msm:text-[13px] sm:text-[12px] lg:text-xs'>
//   {item.latestMessage?.createdAt
//     ? new Date(item.latestMessage.createdAt).toLocaleTimeString([], {
//         hour: '2-digit',
//         minute: '2-digit',
//       })
//     : ''}
// </span>

//               </div>
//             );
//           })
//         ) : (
//          <div className=' h-full flex justify-center items-center flex-col pt-20'><img
//              className='w-[400px] h-[400px]'
//                         // className="absolute top-1/2 left-1/2 w-[50%] h-[60%] transform -translate-x-1/2 -translate-y-1/2 msm:hidden sm:hidden md:flex"
//                         src={nochat}
//                         alt="Listing"
//                     />
//           <div style={styles.noChats}>Your Inbox is Currently Empty</div></div> 
//         )}
//       </div>
//   );
// };

// const styles: { [key: string]: React.CSSProperties } = {
  
  
//   title: {
//     color: 'black',
//     fontFamily: 'Arial, sans-serif',
//     fontSize: '24px',
//     marginTop: '5px',
//     marginBottom: '5px',
//     marginLeft: '1px',
//     marginRight: '1px',
//   },
//   boxContainer: {
//     backgroundColor: '#F5F4F8',
//     height: '75px',
//     borderRadius: '15px',
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: '10px',
//     cursor: 'pointer',
//     marginBottom: '10px',
//   },
//   image: {
//     height: '50px',
//     width: '50px',
//     borderRadius: '50%',
//     borderWidth: '1px',
//     borderColor: 'white',
//   },
 
//   username: {
//     fontSize: '14px',
//     fontWeight: '600',
//     marginBottom: '5px',
//     color: '#000',
//   },
//   message: {
//     fontSize: '12px',
//     color: 'gray',
//     marginBottom: '5px',
//   },
//   time: {
//     fontSize: '9px',
//     color: 'gray',
//   },
//   noChats: {
//     textAlign: 'center',
//     fontSize: '16px',
//     color: 'gray',
//     marginTop: '20px',
//   },
// };

// export default Chatlist;


import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../context/ChatContext';
import { Avatar, Box } from '@mui/material';
import { MdArrowBackIosNew } from 'react-icons/md';
import nochat from '../../compounts/images/nochats.png';
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';

// const ENDPOINT = 'https://chat.vattaram.in';
const ENDPOINT = 'https://chat.vattaram.in';

let socket: any;

interface User {
  company_name: string;
  company_logo: string;
  user_id: string;
  name: string;
  user_pic: string;
  email: string;
}

interface Sender {
  name: string;
  user_pic: string;
  email: string;
  user_id: string;
}

interface LatestMessage {
  content: string;
  createdAt: string;
  sender: Sender;
  chatId: number;
}

interface UserType {
  chatId: number;
  users: User[];
  latestMessage: LatestMessage | null;
}

interface ChatlistProps {
  fetchAgain?: any;
}

const Chatlist: React.FC<ChatlistProps> = ({ fetchAgain }) => {
  const { selectedChat,setSelectedChat } = useChat();
  console.log("selectedChat",selectedChat)
  const [users, setUsers] = useState<UserType[]>([]);
  const [color,setColor]=useState<any>(selectedChat?.chatId??0)
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const userid = loginData.user_id;
  const navigate = useNavigate();
  // Access setSelectedChat from context

  useEffect(() => {
    socket = io(ENDPOINT);
    fetchChats();

    socket.on('connect', () => {
      socket.emit('join', userid);
    });

    socket.on('update last message', (newMessage: any) => {
      console.log('New message received:', newMessage);
      fetchChats();
    });

    return () => {
      socket.off('update last message');
      socket.disconnect();
    };
  }, [userid, fetchAgain]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/api/chat/${userid}`);
      
      // Sorting the chats based on the latest message's createdAt timestamp
      const sortedChats = response.data.sort((a: UserType, b: UserType) => {
        // Handling cases where createdAt might be undefined
        const timeA = a.latestMessage?.createdAt ? new Date(a.latestMessage.createdAt).getTime() : 0;
        const timeB = b.latestMessage?.createdAt ? new Date(b.latestMessage.createdAt).getTime() : 0;
        return timeB - timeA; // Sort in descending order (latest first)
      });
      
      setUsers(sortedChats);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching chat list: ', error);
    }
  };

  const handleChatClick = (item: UserType) => {
  
    const user = item.users[0];
    setColor(item.chatId)
    console.log('check the item', item);
    setSelectedChat({
      chatId: item.chatId,
      userId: user.user_id,
      username: user.name,
      userpic: user.user_pic,
      companylogo: user.company_logo,
      companyname: user.company_name,
    });
  };

  return (
    <div className='custom-scrollbar  msm:h-[84vh] sm:h-[80vh] lg:h-[80vh] xl:h-[85vh] overflow-auto lg:px-3 lg:py-2 sm:px-2 sm:py-2 msm:px-2 msm:py-2'>
      {users.length > 0 ? (
        users.map((item) => {
          const user = item.users[0];
          return (
            <div
            className={`w-full ${color === item.chatId ? "bg-[#dadbddbd]" : "bg-[#F5F4F8]"}`} 
            key={item.chatId}
            style={styles.boxContainer}
            onClick={() => handleChatClick(item)}
          >
            <div className="flex flex-row w-[100%]">
              <div className="w-[10%]">
                {/* Safe check for company_logo */}
                {user?.company_logo ? (
                  <Avatar
                    alt={user?.name}
                    src={user?.company_logo}
                    sx={{
                      bgcolor: '#fff',
                      marginRight: 2,
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      borderColor: 'white',
                    }}
                  />
                ) : (
                  <Box
                    className="rounded-full w-16 h-16 flex items-center justify-center font-bold"
                    sx={{
                      bgcolor: '#0CB7D1',
                      marginRight: 2,
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      fontSize: '17px',
                      borderColor: 'white',
                    }}
                  >
                    {/* Fallback to company_name or user name */}
                    {user?.company_name ? user?.company_name.charAt(0) : user?.name?.charAt(0)}
                  </Box>
                )}
              </div>
          
              <div className="w-[88%] ml-3">
                {/* Safe access to company_name */}
                <div className="msm:text-[15px] sm:text-[15px] lg:text-[13px] xl:text-[14px] font-bold capitalize overflow-hidden whitespace-nowrap text-ellipsis">
                  {user?.company_name || user?.name}
                </div>
          
                <div className="msm:text-[14px] sm:text-[14px] lg:text-xs mt-1 capitalize overflow-hidden whitespace-nowrap text-ellipsis">
                  {item.latestMessage?.content || 'No message yet'}
                </div>
              </div>
            </div>
          
            <div style={styles.time} className="msm:text-[13px] sm:text-[12px] lg:text-xs text-right w-full">
              {item.latestMessage?.createdAt
                ? `${formatDistanceToNowStrict(new Date(item.latestMessage.createdAt))} ago`
                : ''}
            </div>
          </div>
          
          );
        })
      ) : (
        <div className='h-full flex justify-center items-center flex-col pt-20'>
          <img className='w-[400px] h-[400px]' src={nochat} alt='Listing' />
          <div style={styles.noChats}>Your Inbox is Currently Empty</div>
        </div>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  title: {
    color: 'black',
    fontFamily: 'Arial, sans-serif',
    fontSize: '24px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '1px',
    marginRight: '1px',
  },
  boxContainer: {
    //backgroundColor: '#F5F4F8',
    height: '75px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  image: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'white',
  },
  username: {
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '5px',
    color: '#000',
  },
  message: {
    fontSize: '12px',
    color: 'gray',
    marginBottom: '5px',
  },
  time: {
    fontSize: '9px',
    color: 'gray',

  },
  noChats: {
    textAlign: 'center',
    fontSize: '16px',
    color: 'gray',
    marginTop: '20px',
  },
};

export default Chatlist;
