import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Map from "./compounts/Map";
import Search from "./compounts/Search";
import Skeleton from "./compounts/Skeleton";
import Fskeleton from "./compounts/Fskeleton";
import Scroll from "./compounts/Scroll";

import Main from './compounts/homepage/main'
import Property from './compounts/Property'
import PropertyDetails from "./compounts/PropertyDetails";
import Model from './compounts/loginForm/Modal'// LOgin first way
import LoginPostList from "./compounts/LoginPostList/List"; //login form with login but not give the category
import LoginPost from "./compounts/LoginPost/Modal" // without login to post add
import NavbarMain from "./compounts/homepage/NavbarMain";
import Profile from "./compounts/homepage/Profile";
import Edit from "./compounts/homepage/Edit";
import Card from "./compounts/homepage/Card";
import CardComponent from "./Properties/CardComponent";
import PropertiesCard from "./SavePropertes/PropertiesCard";
import Listings from "./compounts/Listings";
import ContactUser from "./compounts/ContactUser";
import EditForm from "./compounts/EditForm";
import ReportMain from "./compounts/ReportMain";
import ReportPopUp from "./compounts/ReportPopUp";
import BasicRating from "./compounts/RateingBox/BasicRating";
import RateingBox from "./compounts/RateingBox/RateingBox";
import VattaraExclusive from "./compounts/VattaraVerified/VattaraExclusive";


import ImageCarousel from "./compounts/Services/ImageCarousel";
import ReliableServices from "./compounts/Services/ReliableServices";
import ServicesDetails from "./compounts/Services/ServicesDetails";
import PostService from "./compounts/Services/PostService";
import LoginHistory from "./compounts/Login History/LoginHistory";
import Subscription from "./compounts/Subscription/Subscription";
import PrivacyPolicy from "./Privacy Policy/PrivacyPolicy";
import Builder from "./compounts/Builders/Builder";
import BuilderPropertyDetails from "./compounts/Builders/BuilderPropertyDetails";
import Footer from "./compounts/Footer/Footer";
import TermsandConditions from "./compounts/Terms and Conditions/TermsandConditions";
import Career from "./compounts/Career/Career";
import JobDetails from "./compounts/Career/JobDetails";
import MNavbar from "./compounts/homepage/MNavber";
import Settings from "./compounts/homepage/Settings";
import ChatScreen from './compounts/Chat/ChatScreen';
import { ChatProvider } from './compounts/context/ChatContext';
import Chatlist from './compounts/Chat/Chatlist';
// import SingleChat from "./compounts/Chat/SingleChat";
import UpComingProjects from "./compounts/homepage/UpComingproject";
import Form from "./compounts/PostForm/Form";
import { FormProvider } from "./compounts/EditFrom/component/StepperContext";
import Filter from "./compounts/filter/Filter";
import { FormProvider1 } from "./compounts/PostForm/component/StepperContext";
import SharepropDetails from "./compounts/share/SharepropDetails";
import HelpCenter from "./compounts/HelpCenter/HelpCenter";
import PrivateRoute from "./compounts/PrivateRoute";
import HelpCentermobile from "./compounts/HelpCenter/HelpCentermobile";



function App() {


 

 
  return (
    <div className="h-fit">
      <ChatProvider>
        <FormProvider>
        <FormProvider1>
          <BrowserRouter>
          
            <div className="msm:hidden sm:flex md:flex">
              <NavbarMain />

            </div>

            <Routes>
              <Route path="PostForm" element={<Form />} />
              <Route path="/myListingView" element={<BuilderPropertyDetails />} />
              <Route path="/Subscription" element={<Subscription />} />
              <Route path="/loginhistory" element={<LoginHistory />} />
              <Route path="/postservice" element={<PostService />} />
              <Route path="/services" element={<ReliableServices />} />
              <Route path="/servicesDetails" element={<ServicesDetails />} />
              <Route path="/exclusive" element={<VattaraExclusive />} />
              <Route path="/editform" element={<EditForm />} />
              <Route path="/contactUser" element={<ContactUser />} />
              <Route path="/list" element={<Listings />} />
              <Route path="/cart" element={<PropertiesCard />} />
              <Route path="/edit" element={<Edit />} />
              <Route element={<PrivateRoute />}> 
                <Route path="/profile" element={<Profile />} />
                 </Route>
              <Route path="/loginPostList" element={<LoginPostList />} />
              <Route path="/loginPost" element={<LoginPost />} />
              <Route path="/contact" element={<Model />} />
              <Route path="/" element={<Main />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/builder" element={<Builder />} />
              <Route path="/footer" element={<Footer />} />
              <Route path="/terms" element={<TermsandConditions />} />
              <Route path="/career" element={<Career />} />
              <Route path="/jobs" element={<JobDetails />} />
              <Route path="/chatscreen" element={<ChatScreen />} />
              <Route path="/chatlist" element={<Chatlist />} />
           
              <Route path="/settings" element={<Settings />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path='/upcoming-projects' element={<UpComingProjects />} />
              <Route path="/Property" element={<Property />} />
              <Route path="/PropertyDetails/:id/:propertyType/:lookingTo/:Bvieww" element={<PropertyDetails />} />
              <Route path="/property/:id/:propertyType/:lookingTo" element={<SharepropDetails />} /> 
              <Route path="/helpCenter" element={<HelpCenter/>}/>
              <Route path="/helpCentermobile" element={<HelpCentermobile/>}/>


            </Routes>
            {/* <Footer/> */}
          </BrowserRouter>
          </FormProvider1>
        </FormProvider>
      </ChatProvider>
    </div>
  );
}

export default App;
